import './App.css';
import { Container, Card, Button, Jumbotron, Row} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import helmet from "./img/helmet.png";
import helmet2 from "./img/helmet2.png";
import epress from "./img/epress.png";
import pressreader from "./img/pressreader.png";
import varaamo from "./img/varaamo.png";
import kunnari from "./img/kunnari.png";
import calendar from "./img/calendar.png";


const lngs = {
  fi: { nativeName: 'Suomi' },
  sv: { nativeName: 'Svenska' },
  en: { nativeName: 'English' },
  rus: { nativeName: 'Русский' }
};

function App() {
  const { t, i18n } = useTranslation();

  const data = {
    haku: {
      url: "https://helmet.finna.fi",
      img: helmet2,
      show: true,
      title: t("haku.title"),
      text: t("haku.text")
    },
    epress: {
      url: "https://helmet.epress.fi/catalog",
      img: epress,
      show: true,
      title: t("epress.title"),
      text: t("epress.text")
    },
    emagz: {
      url: "https://helmet.emagz.fi/ext/login/helmet",
      img: epress,
      show: false,
      title: t("emagz.title"),
      text: t("emagz.text")
    },
    pressreader: {
      url: "https://www.pressreader.com",
      img: pressreader,
      show: true,
      title: t("pressreader.title"),
      text: t("pressreader.text")
    },
    varaamo: {
      url: "https://varaamo.espoo.fi",
      img: varaamo,
      show: true,
      title: t("varaamo.title"),
      text: t("varaamo.text")
    },
    kunnari: {
      url: "https://www.kunnarilistat.fi",
      img: kunnari,
      show: true,
      title: t("kunnarilistat.title"),
      text: t("kunnarilistat.text")
    },
    tapahtumat: {
      url: "https://helmet.finna.fi/Content/tapahtumat",
      img: calendar,
      show: true,
      title: t("tapahtumat.title"),
      text: t("tapahtumat.text")
    },
    register: {
      url: t("register.url"),
      img: helmet2,
      show: true,
      title: t("register.title"),
      text: t("register.text")
    }
  };

  let list;
  let secondary;

  if(window.location.host.includes('lehdet')){
    data.varaamo.show = false
    data.kunnari.show = false
    data.tapahtumat.show = false
    data.register.show = false
    secondary = <Container><div className="secondary">{t("title.text")}</div></Container>
  }

   list = Object.keys(data).map((item, i) => {
     
     if(data[item].show){
       return(

  <Card key={i}>
    <a href={data[item].url}>
      <div className="thumbnail d-flex">
        <Container className="justify-content-center">
        <Row className="h-100">
      <Card.Img variant="top my-auto" src={data[item].img}  />
      </Row>
        </Container>
      </div>
      <Card.Body>
        <Card.Title><b>{data[item].title}</b></Card.Title>
        <Card.Text>{data[item].text}</Card.Text>
      </Card.Body>
    </a>
  </Card>

       )
     } else {
       return null
     }

     
  })

  return(
<>
  <Jumbotron className="top text-center">
    <Container>
      <a href="https://www.helmet.fi">
      <img src={helmet} alt="." width="200"/>
      </a>
    </Container>
    {secondary}
    
    <Container className="langButtons ml-auto mr-auto">
        {Object.keys(lngs).map((lng) => (
          <Button variant="outline-light"key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 
            'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
            {lngs[lng].nativeName}
          </Button>
        ))}
    </Container>
  </Jumbotron>

  <Jumbotron className="mainJumbo d-flex">
    <Container>
    <Row className="kortit flex-wrap">
      {list}
      </Row>
      </Container>
    </Jumbotron>
</>
  )
}

export default App;
