import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  // detect user language 
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    order: ['querystring', 'navigator', 'htmlTag', 'path', 'subdomain'],
    debug: false,
    useCookie: false,
    fallbackLng: 'fi',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {

          "title": { "text": "This computer is only for reading magazines and newspapers" },

          "epress": {
            "title": "Finnish newspapers",
            "text": "Helsingin Sanomat and 200 other local Finnish newspapers"
          },

          "emagz2": {
            "title": "Newspapers and magazines available at the library",
            "text": "Iltalehti, Iltasanomat, Seiska, Kauppalehti, Talouselämä, etc."
          },

          "emagz": {
            "title": "Finnish magazines",
            "text": "Over 50 popular Finnish magazines"
          },

          "pressreader": {
            "title": "International newspapers",
            "text": "Over 3500 newspapers and magazines from 100 countries and 54 languages"
          },

          "varaamo": {
            "title": "Varaamo",
            "text": "You can book from Varaamo conference rooms, workspaces and equipment for private use"
          },

          "kunnarilistat": {
            "title": "Kunnarilistat.fi",
            "text": "Check which Kunnari-reading diploma books are available from Helmet libraries"
          },

          "tapahtumat": {
            "title": "Events",
            "text": "Espoo city library’s upcoming events"
          },

          "haku": {
            "title": "Search",
            "text": "Search for items"
          },
            
          "register": {
            "title": "Register",
            "text": "Register to become a member of the Library",
            "url": "https://luettelo.helmet.fi/selfreg*eng~S9"
          },


        }
      },
      fi: {
        translation: {

          "title": { "text": "Tämä tietokone on tarkoitettu vain lehtien lukemiseen" },

          "epress": {
            "title": "Kotimaiset sanomalehdet",
            "text": "Helsingin Sanomat ja 200 muuta kotimaista paikallis- ja maakuntalehteä"
          },

          "emagz2": {
            "title": "Kirjastossa luettavat sanoma- ja aikakauslehdet",
            "text": "Iltalehti, Iltasanomat, Seiska, Kauppalehti, Talouselämä, ym."
          },

          "emagz": {
            "title": "Kotimaiset aikakauslehdet",
            "text": "Yli 50 suosittua kotimaista aikakauslehteä"
          },

          "pressreader": {
            "title": "Ulkomaiset sanomalehdet",
            "text": "Yli 3500 sanoma- ja aikakauslehteä 100 maasta 54 kielellä"
          },

          "varaamo": {
            "title": "Varaamo",
            "text": "Varaamosta voit varata julkisia tiloja ja laitteita omaan käyttöösi"
          },

          "kunnarilistat": {
            "title": "Kunnarilistat.fi",
            "text": "Kunnari-lukudiplomikirjojen saatavuustiedot Helmet-kirjastoista"
          },

          "tapahtumat": {
            "title": "Tapahtumat",
            "text": "Espoon kaupunginkirjaston tulevat tapahtumat"
          },

          "haku": {
            "title": "Haku",
            "text": "Hae aineistoa"
          },
          
          "register": {
            "title": "Rekisteröidy",
            "text": "Rekisteröidy kirjaston asiakkaaksi",
            "url": "https://luettelo.helmet.fi/selfreg*fin~S9"
          },


        }
      },
      sv: {
        translation: {

          "title": { "text": "Denna dator är enbart för tidningsläsning" },

          "epress": {
            "title": "Inhemska dagstidningar",
            "text": "Helsingin Sanomat och 200 andra inhemska lokal- och landskapstidningar"
          },

          "emagz2": {
            "title": "Dagstidningar och tidskrifter som kan bara läsas i biblioteket",
            "text": "Iltalehti, Iltasanomat, Seiska, Kauppalehti, Talouselämä, etc."
          },

          "emagz": {
            "title": "Inhemska tidskrifter",
            "text": "Över 50 populära inhemska tidskrifter"
          },

          "pressreader": {
            "title": "Utländska tidningar",
            "text": "Över 3500 dagstidningar och tidskrifter från 100 länder på 54 olika språk"
          },

          "varaamo": {
            "title": "Varaamo",
            "text": "Med Varaamo kan du boka offentliga utrymmen och apparater för privat bruk"
          },

          "kunnarilistat": {
            "title": "Kunnarilistat.fi",
            "text": "Kolla vilka Kunnari-läsediplomböcker finns lediga på Helmet-biblioteken"
          },

          "tapahtumat": {
            "title": "Evenemang",
            "text": "Esbo stadsbibliotekets kommande evenemang"
          },

          "haku": {
            "title": "Sökning",
            "text": "Sök material"
          },

          "register": {
            "title": "Registrera dig",
            "text": "Registrera dig som bibliotekskund",
            "url": "https://luettelo.helmet.fi/selfreg*swe~S9"
          },


        }
      },
      rus: {
        translation: {

          "title": { "text": "Этот компьютер только для чтения газет" },

          "epress": {
            "title": "Местные газеты",
            "text": "Helsingin Sanomat и 200 других местных газетых."
          },

          "emagz2": {
            "title": "В Библиотеке читаемые газеты и журналы",
            "text": "Iltalehti, Iltasanomat, Seiska, Kauppalehti, Talouselämä, итд."
          },

          "emagz": {
            "title": "Местные журналы",
            "text": "Более 50 местных журналов"
          },

          "pressreader": {
            "title": "Зарубежные газеты",
            "text": "Более 3500 журналов и газет из 100 стран на 54 языках."
          },

          "varaamo": {
            "title": "Varaamo",
            "text": "Через Varaamo можно забронировать помещения и устройствa."
          },

          "kunnarilistat": {
            "title": "Kunnarilistat.fi",
            "text": "Информация о наличие книг для диплома читателя."
          },

          "tapahtumat": {
            "title": "Мероприятия",
            "text": "Мероприятия в библиотеках Еспоо"
          },

          "haku": {
            "title": "Поиск",
            "text": "Поиск"
          },

          "register": {
            "title": "Регистрация",
            "text": "Регистрация клиента",
            "url": "https://luettelo.helmet.fi/selfreg*rus~S9"
          },


        }
      }
    }
  });



export default i18n;